body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f9f9f9;
  color: #333;
  margin-top: 0;
  margin-bottom: 30px;
  padding-top: 0;
  padding-bottom: 10px;
}

/* Logo Container Styles */
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #050676;
  padding: 0;
  margin: 0;
}

.logo-container img {
  max-height: 100px;
  max-width: 150px;
  transition: transform 0.3s;
}

.logo-container img:hover {
  transform: scale(1.1);
}

/* Responsive Navigation */
nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #050676;
  padding: 0.5rem 1rem;
  flex-wrap: wrap;
  position: relative;
}

nav a {
  color: white;
  text-decoration: none;
  margin: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  transition: color 0.3s;
}

nav a:hover {
  color: #f2de52;
}

.nav-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #050676;
  padding: 0.5 rem 0;
}

.nav-links a {
  margin: 0 0.8rem;
  padding: 0.2rem 0;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
}

.nav-links a:hover {
  text-decoration: underline;
}

.nav-links.active {
  display: flex;
}

/* Media Query for smaller screens (Mobile View) */
@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    /* Stack links vertically for mobile */
    align-items: center;
    /* Center them */
    display: none;
    /* Hidden by default, toggle with the hamburger menu */
  }

  .nav-links.active {
    display: flex;
    /* Show menu when active */
  }

  .nav-links a {
    margin: 1rem 0;
    /* Add spacing between links vertically */
  }
}

/* Hamburger Icon */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  height: 20px;
  width: 25px;
  cursor: pointer;
}

.hamburger span {
  background: white;
  height: 3px;
  width: 100%;
  transition: 0.3s;
}

.hamburger.active span:nth-child(1) {
  transform: rotate(45deg) translateY(10px);
}

.hamburger.active span:nth-child(2) {
  opacity: 0;
}

.hamburger.active span:nth-child(3) {
  transform: rotate(-45deg) translateY(-10px);
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .hamburger {
    display: flex;
  }
}

/* Style the Carousel container */
.carousel {
  touch-action: pan-y;
}

.carousel .slide img {
  object-fit: contain;
  max-height: 600px;
  width: 100%;
  margin: 0 auto;
}

.carousel .control-arrow {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  padding: 100px;
}

.carousel .control-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel .control-dots {
  bottom: 10px;
}

.carousel .dot {
  background-color: rgba(255, 255, 255, 0.7);
}

.carousel .dot.selected {
  background-color: #FFD700;
}

/* Main content styles */
main {
  max-width: 1000px;
  margin: 2rem auto;
  padding: 1rem;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

main h1 {
  font-size: 2.5rem;
  color: #050676;
  margin-bottom: 1rem;
}

main p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
}

/* Team Gallery Section */
.team-gallery {
  text-align: center;
  padding: 2rem;
  background-color: #f9f9f9;
}

.team-gallery h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

.team-member {
  max-width: 200px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.team-member:hover {
  transform: translateY(-5px);
}

.team-member img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  image-rendering: crisp-edges;
  border: 2px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.team-member h3 {
  font-size: 1.2rem;
  margin-top: 0.5rem;
  color: #555;
}

.team-member p {
  font-size: 1rem;
  color: #777;
}

/* Contact Section Styles */
.contact {
  margin: 0;
  padding: 0;
  background-color: white;
  max-width: 1000px;
  text-align: center;
}

.contact h2 {
  font-size: 2.5rem;
  color: #050676;
  margin-bottom: 1rem;
}

.contact p {
  color: #34495e;
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.contact img {
  display: block;
  margin: 0 auto;
  max-width: 40%;
}

/* Social Media Section Styles */
.social-media {
  margin: 2rem auto;
  padding: 30px;
  background-color: rgb(232, 232, 232);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  text-align: center;
}

.social-media h3 {
  color: #2c3e50;
  margin-bottom: 15px;
  font-size: 1.5rem;
}

.social-media p {
  color: #34495e;
  font-size: 1.2rem;
}

.social-media ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.social-media ul li {
  display: inline-block;
  margin: 0 10px;
}

.social-media ul li a {
  color: #050676;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.social-media ul li a i {
  font-size: 1.5rem;
  margin-right: 8px;
}

/* Donation Section Styles */
.donate {
  margin: 0;
  padding: 0;
  background-color: transparent;
  max-width: 1000px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
  padding-top: 0;
  padding-bottom: 20px;
}

.donate h2 {
  font-size: 2.5rem;
  color: #050676;
  margin-bottom: 1rem;
}

.donate img {
  display: block;
  margin: 0 auto;
  max-width: 40%;
}

.donate p {
  font-size: 1rem;
  color: #333;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.donate-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.donate-link,
.gofundme-button {
  display: inline-block;
  width: 90%;
  max-width: 500px;
  margin-top: 5px;
  text-align: center;
  padding: 10px;
  background-color: #050676;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  border: none;
}

.donate-link:hover,
.gofundme-button:hover {
  background-color: #f2de52;
  transform: scale(1.03);
}

.donate-image:hover {
  transform: none;
}

/* Footer styles */
footer {
  text-align: center;
  padding: 1rem;
  background-color: #050676;
  color: white;
  margin-top: 2rem;
  position: fixed;
  bottom: 0;
  width: 100%;
}